import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Preloader from "./Preloader";
import ReactMarkdown from "react-markdown";
import DiffDisplayer from "./DiffDisplayer";
import { PROJECTS } from "../consts";
import GitHubIcon from "@mui/icons-material/GitHub";
import dockerhub from "../assets/dockerHub.png";

const styles = {
  header: {
    height: "48px",
  },
  headerLeftColumn: {
    width: "400px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRightColumn: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  container: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  link: {
    fill: "black",
    height: "24px",
    marginRight: "10px",
  },
  setShowPrerelease: {
    float: "right",
  },
};

const projectsByValue = () => {
  const projectsByValue = {};

  Object.values(PROJECTS).forEach((project) => {
    projectsByValue[project.value] = project;
  });

  return projectsByValue;
};

const getTagDigestPairs = (project) =>
  new Promise((resolve, reject) => {
    let images = [];

    const fetchDockerImages = async (nextCallUrl) => {
      const { data: imagesBunch } = await axios.get(
        process.env.NODE_ENV === "development"
          ? "http://localhost:7071/api/fetchDHImages/"
          : "/api/fetchDHImages",
        { params: { project, url: nextCallUrl } }
      );

      images = [...images, imagesBunch];

      if (imagesBunch.next) {
        fetchDockerImages(imagesBunch.next);
      } else {
        let allDockerImages = [];
        const tagDigestPairs = {};

        images.forEach((imagesBatch) => {
          allDockerImages = [...allDockerImages, ...imagesBatch.results];
        });

        allDockerImages.forEach((image) => {
          image.tags.forEach((e) => {
            tagDigestPairs[e.tag] = image.digest;
          });
        });

        resolve(tagDigestPairs);
      }
    };

    fetchDockerImages();
  });

const ReleaseList = ({ project }) => {
  const projects = projectsByValue();
  const [releases, setReleases] = useState([]);
  // 0 = initial empty state, 1 = loading data, 2 = data loaded
  const [contentLoading, setContentLoading] = useState(0);

  const [accordionsOpen, setAccordionsOpen] = useState([]);

  const [showPrerelease, setShowPrerelease] = useState(false);

  const [tagDigestPairs, setTagDigestPairs] = useState({});

  const [readme, setReadme] = useState(null);

  const handleExpandedChange = (index) => {
    const newState = accordionsOpen.slice();
    newState[index] = !newState[index];
    setAccordionsOpen(newState);
  };

  const formatDate = (date) => {
    date = new Date(date);
    return `${date.getDate()}. ${
      date.getMonth() + 1
    }. ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
  };

  const parseChangelogBody = (changelogBody) => {
    // It is still easier to remove what we don't need but the opposite approach may be needed in the future
    const linkRemoveRegex = new RegExp(
      /[#\s]*\[[^[\]]*\]\([^)(]*\)[\n]*( [^)]*\))?/,
      "g"
    );

    const fullChangelogRegex = new RegExp(
      /\*\*Full Changelog\*\*: [^\s]*/,
      "g"
    );

    const buildSystemRegex = new RegExp(/### Build Systems[^\n(]*/, "g");

    const headlineRegex = new RegExp(/## What's Changed\r\n/, "g");

    const gitHubLinkRegex = new RegExp(/by @.*[^\n]/, "g");

    changelogBody = changelogBody.replace(linkRemoveRegex, "");
    changelogBody = changelogBody.replace(fullChangelogRegex, "");
    changelogBody = changelogBody.replace(headlineRegex, "");
    changelogBody = changelogBody.replace(buildSystemRegex, "");
    changelogBody = changelogBody.replace(gitHubLinkRegex, "");
    // Empty parentheses ()
    changelogBody = changelogBody.replace(new RegExp(/(\(\))/, "g"), "");

    return changelogBody;
  };

  useEffect(() => {
    setTagDigestPairs({});

    const fetchDataForProject = async () => {
      // PRELOADER ON
      setContentLoading(1);

      // RELEASES
      const { data } = await axios.get(
        process.env.NODE_ENV === "development"
          ? "http://localhost:7071/api/fetchReleases/"
          : "/api/fetchReleases",
        {
          params: { project },
        }
      );

      const parsedAndFilteredReleases = data
        .map((data) => ({
          ...data,
          body: parseChangelogBody(data.body),
        }))
        .filter((release) => release.body);

      setReleases(parsedAndFilteredReleases);

      setAccordionsOpen(
        Array.from(parsedAndFilteredReleases, (release, index) => index < 10)
      );

      // READ ME
      const { data: readmeHtml } = await axios.get(
        process.env.NODE_ENV === "development"
          ? "http://localhost:7071/api/fetchReadme/"
          : "/api/fetchReadme",
        {
          params: { project },
        }
      );

      setReadme(readmeHtml);

      // PRELOADER OFF
      setContentLoading(2)

      // DIGEST
      setTagDigestPairs(await getTagDigestPairs(project));

    };

    if (project) {
      fetchDataForProject(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <div>
      {contentLoading === 1 && <Preloader />}
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href={projects[project].github}
        title={"GitHub: " + projects[project].github}
      >
        <GitHubIcon style={styles.link} />
      </a>
      <a
        style={styles.githubLink}
        target="_blank"
        rel="noreferrer"
        href={projects[project].dockerhub}
        title={"DockerHub: " + projects[project].dockerhub}
      >
        <img src={dockerhub} alt="Dockerhub" style={styles.link} />
      </a>
      <br />
      <br />
      <Accordion>
        <AccordionSummary
                    expandIcon={<ExpandMore />}
                    style={styles.header}
                  >
                    <div style={styles.headerLeftColumn}>
                      <h2>{project.toUpperCase()} README</h2>
                    </div>
                  </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{ __html: readme }} />
        </AccordionDetails>
      </Accordion>
      <br />
      <Typography variant="h6" component="div" gutterBottom>
        Select release versions for comparison:
      </Typography>
      <DiffDisplayer releases={releases.filter(({ isPrerelease }) => showPrerelease || !isPrerelease)} />
      <div style={styles.setShowPrerelease}>
        Show pre-releases (NON STABLE VERSIONS)
        <Checkbox
          label="Label"
          checked={showPrerelease}
          onChange={() => setShowPrerelease(!showPrerelease)}
        />
      </div>
      <Typography variant="h6" component="div" gutterBottom>
        List of releases:
      </Typography>
      <div style={styles.container}>
        {releases
          .filter(({ isPrerelease }) => showPrerelease || !isPrerelease)
          .map(
            (release, index) =>
              release.body && (
                <Accordion
                  key={index}
                  expanded={!!accordionsOpen[index]}
                  onChange={() => handleExpandedChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    style={styles.header}
                  >
                    <div style={styles.headerLeftColumn}>
                      <h2>{release.tagName}</h2>
                      {release.isPrerelease && <Chip label="prerelease" />}
                    </div>
                    <div style={styles.headerRightColumn}>
                      Created: {formatDate(release.createdAt)}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    DIGEST: {tagDigestPairs?.[release.tagName]}
                    <ReactMarkdown children={release.body} />
                  </AccordionDetails>
                </Accordion>
              )
          )}
      </div>
    </div>
  );
};

export default ReleaseList;
