import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const styles = {
  diffContainer: {
    padding: "15px",
    margin: "15px",
  },
  selectsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const DiffSelect = ({ options, value, onChange, label, hideOptions, startingIndex }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 250 }}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        value={value}
        onChange={onChange}
        variant="outlined"
        label={label}
      >
        {options.map(
          (option, index) =>
          (!option.isPrerelease ?
            ((!hideOptions || index <= startingIndex) && (
              <MenuItem key={index} value={index}>
                {option.tagName}
              </MenuItem>
            )) : ((!hideOptions || index <= startingIndex) && (
              <MenuItem key={index} value={index} sx={{ marginLeft: 5}}>
                {option.tagName}
              </MenuItem>
            )))
        )}
      </Select>
    </FormControl>
  );
};

const DiffDisplayer = ({ releases }) => {
  const [startingIndex, setStartingIndex] = useState(0);
  const [finalIndex, setFinalIndex] = useState(0);

  // Releases are sorted from the newest, the indices from <Selects/> have to be reversed
  const diffedReleases = releases.slice(finalIndex, startingIndex);

  useEffect(() => {
    setStartingIndex(0);
    setFinalIndex(0);
  }, [releases]);

  return (
    <Paper style={styles.diffContainer}>
      <Box style={styles.selectsContainer}>
        <DiffSelect
          label="My current version"
          options={releases}
          value={startingIndex}
          onChange={(e) => setStartingIndex(e.target.value)}
        />
        <span>{"<-->"}</span>
        <DiffSelect
          label="Target update version"
          options={releases}
          value={finalIndex}
          hideOptions
          startingIndex={startingIndex}
          onChange={(e) => setFinalIndex(e.target.value)}
        />
      </Box>
      {diffedReleases.map((release) => (
        <div key={release.tagName}>
          <span>{release.tagName}</span>
          <ReactMarkdown children={release.body} />
        </div>
      ))}
    </Paper>
  );
};

export default DiffDisplayer;
