import "./App.css";
import React from "react";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import Documentation from "./pages/Documentation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7fbd42",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Dashboard} />
          <Route path="/docucmentation" component={Documentation} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
