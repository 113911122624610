export const APP_SECTIONS = {
  CHANGELOG: "changelog",
  DOCS: "documentation",
};

export const PROJECTS = {
  BUILDER: {
    dockerhub:
      "https://hub.docker.com/repository/docker/borndigitalaibot/builder",
    github: "https://github.com/Born-Digital-AI/builder",
    label: "Builder",
    value: "builder",
  },
  NLP: {
    dockerhub:
      "https://hub.docker.com/repository/docker/borndigitalaibot/nlp-engine",
    github: "https://github.com/Born-Digital-AI/nlp-engine",
    label: "NLP Engine",
    value: "goodbot",
  },
  BUBBLE: {
    dockerhub: "https://hub.docker.com/repository/docker/borndigitalaibot/bubble",
    github: "https://github.com/Born-Digital-AI/bubble",
    label: "Chat bubble",
    value: "bubble",
  },
  BUILDER_SERVER: {
    dockerhub: "https://hub.docker.com/repository/docker/borndigitalaibot/builder-server",
    github: "https://github.com/Born-Digital-AI/builder-server",
    label: "Builder server",
    value: "builder-server",
  },
  VOICE: {
    dockerhub: "https://hub.docker.com/repository/docker/borndigitalaibot/voice",
    github: "https://github.com/Born-Digital-AI/voice",
    label: "Voice",
    value: "voice",
  },
  VOICE_CONNECTOR: {
    dockerhub: "https://hub.docker.com/repository/docker/borndigitalaibot/voice-connector",
    github: "https://github.com/Born-Digital-AI/voice-connector",
    label: "Voice connector",
    value: "voice-connector",
  },
};
