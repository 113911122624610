import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Preloader from "./Preloader";
import ReactMarkdown from "react-markdown";
import DiffDisplayer from "./DiffDisplayer";

const styles = {
  headerLeftColumn: {
    width: "200px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRightColumn: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  container: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
};

const DocumentationPage = ({ project }) => {
  const [releases, setReleases] = useState([]);
  // 0 = initial empty state, 1 = loading data, 2 = data loaded
  const [contentLoading, setContentLoading] = useState(0);

  const [accordionsOpen, setAccordionsOpen] = useState([]);

  const handleExpandedChange = (index) => {
    const newState = accordionsOpen.slice();
    newState[index] = !newState[index];
    setAccordionsOpen(newState);
  };

  const formatDate = (date) => {
    date = new Date(date);
    return `${date.getDate()}. ${
      date.getMonth() + 1
    }. ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
  };

  const parseChangelogBody = (changelogBody) => {
    // It is still easier to remove what we don't need but the opposite approach may be needed in the future
    const linkRemoveRegex = new RegExp(
      /[#\s]*\[[^[\]]*\]\([^)(]*\)[\n]*( [^)]*\))?/,
      "g"
    );

    const fullChangelogRegex = new RegExp(
      /\*\*Full Changelog\*\*: [^\s]*/,
      "g"
    );

    const buildSystemRegex = new RegExp(/### Build Systems[^\n(]*/, "g");

    const headlineRegex = new RegExp(/## What's Changed\r\n/, "g");

    changelogBody = changelogBody.replace(linkRemoveRegex, "");
    changelogBody = changelogBody.replace(fullChangelogRegex, "");
    changelogBody = changelogBody.replace(headlineRegex, "");
    changelogBody = changelogBody.replace(buildSystemRegex, "");
    // Empty parentheses ()
    changelogBody = changelogBody.replace(new RegExp(/(\(\))/, "g"), "");

    return changelogBody;
  };

  useEffect(() => {
    const fetchDataForProject = async () => {
      setContentLoading(1);

      const { data } = await axios.get(
        process.env.NODE_ENV === "development"
          ? "http://localhost:7071/api/fetchReleases/"
          : "/api/fetchReleases",
        {
          params: { project },
        }
      );

      const parsedAndFilteredReleases = data
        .map((data) => ({
          ...data,
          body: parseChangelogBody(data.body),
        }))
        .filter((release) => release.body);

      setReleases(parsedAndFilteredReleases);

      setAccordionsOpen(
        Array.from(parsedAndFilteredReleases, (release, index) => index < 10)
      );

      setContentLoading(2);
    };

    if (project) {
      fetchDataForProject(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <div>
      <DiffDisplayer releases={releases} />
      {contentLoading === 1 && <Preloader />}
      <div style={styles.container}>
        {releases.map(
          (release, index) =>
            release.body && (
              <Accordion
                key={index}
                expanded={!!accordionsOpen[index]}
                onChange={() => handleExpandedChange(index)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <div style={styles.headerLeftColumn}>
                    <h2>{release.tagName}</h2>
                    {release.isPrerelease && <Chip label="prerelease" />}
                  </div>
                  <div style={styles.headerRightColumn}>
                    Created: {formatDate(release.createdAt)}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown children={release.body} />
                </AccordionDetails>
              </Accordion>
            )
        )}
      </div>
    </div>
  );
};

export default DocumentationPage;
