import React, { useState } from "react";
import { Container, CssBaseline, Tab, Tabs, Toolbar } from "@mui/material";
import PageHeader from "../components/PageHeader";
import { PROJECTS } from "../consts";
import { Box } from "@mui/system";
import DocumentationPage from "../components/DocumentationPage";

const Documentation = () => {
  const [selectedProject, setSelectedProject] = useState(PROJECTS.BUILDER);

  return (
    <Container>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <PageHeader />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Tabs value={selectedProject} variant="fullWidth">
            {Object.values(PROJECTS).map((project) => (
              <Tab
                key={project}
                label={project}
                value={project}
                onClick={() => setSelectedProject(project)}
              />
            ))}
          </Tabs>
          <DocumentationPage project={selectedProject} />
        </Box>
      </Box>
    </Container>
  );
};

export default Documentation;
