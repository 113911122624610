import {
  Build as BuildIcon,
  ChangeCircle as ChangeCircleIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const styles = {
  logo: {
    width: "125px",
    float: "right",
  },
  headline: {
    color: "white",
    fontSize: "36px",
    padding: "5px",
    paddingLeft: "10px",
  },
};

function ListItemLink(props) {
  const { icon, primary, to, external } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return (
          <NavLink
            to={external ? { pathname: to } : to}
            target={external ? "_blank" : ""}
            ref={ref}
            {...itemProps}
            role={undefined}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const PageHeader = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" component="div" style={styles.headline}>
          Changelog
        </Typography>
        <img src="logo_w.png" alt="logo" style={styles.logo} />
      </AppBar>
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItemLink
              to="/"
              primary="Changelog"
              icon={<ChangeCircleIcon />}
            />
            <ListItemLink
              to="/documentation"
              primary="Documentation"
              icon={<DescriptionIcon />}
            />
            <ListItemLink
              external
              to="https://hub.docker.com/orgs/borndigitalaibot/repositories"
              primary="Docker Hub"
              icon={<BuildIcon />}
            />
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default PageHeader;
