import React, { useState } from "react";
import { Container, CssBaseline, Tab, Tabs, Toolbar } from "@mui/material";
import PageHeader from "../components/PageHeader";
import ReleaseList from "../components/ReleaseList";
import { PROJECTS } from "../consts";
import { Box } from "@mui/system";

const Dashboard = () => {
  const [selectedProject, setSelectedProject] = useState(
    PROJECTS.BUILDER.value
  );

  return (
    <Container sx={{ maxWidth: "1500px !important" }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <PageHeader />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Tabs
            value={selectedProject}
            variant="fullWidth"
            sx={{ marginBottom: "14px" }}
          >
            {Object.values(PROJECTS).map(({ value, label }) => (
              <Tab
                key={value}
                label={label}
                value={value}
                onClick={() => setSelectedProject(value)}
              />
            ))}
          </Tabs>
          <ReleaseList project={selectedProject} />
        </Box>
      </Box>
    </Container>
  );
};

export default Dashboard;
